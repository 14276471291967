var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: "1200" },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { color: "#fafafa" } },
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", flat: "", color: "#1C3144" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      _vm.show = false
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", [_vm._v(_vm._s(_vm.contract.Name))]),
              _c("v-spacer"),
              _c("v-toolbar-title", [_vm._v(_vm._s(_vm.contract.ContractID))])
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "ma-0 pa-6", attrs: { id: "wrapper" } },
            [
              _c(
                "div",
                { attrs: { id: "left" } },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c("label", [_vm._v("Contract Type")]),
                        _c("p", [_vm._v(_vm._s(_vm.contract.ContractType))])
                      ]),
                      _c("v-col", { attrs: { cols: "4" } }, [
                        _c("label", [_vm._v("Ad Group")]),
                        _c("p", [_vm._v(_vm._s(_vm.contract.AdGroup))])
                      ]),
                      _c("v-col", { attrs: { cols: "4" } }, [
                        _c("label", [_vm._v("Supplier")]),
                        _c("p", [_vm._v(_vm._s(_vm.contract.Supplier))])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c("label", [_vm._v("Department")]),
                        _c("p", [_vm._v(_vm._s(_vm.contract.Department))])
                      ]),
                      _c("v-col", { attrs: { cols: "9" } }, [
                        _c("label", [_vm._v("Manufacturer")]),
                        _c("p", [_vm._v(_vm._s(_vm.contract.Manufacturer))])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { attrs: { id: "right" } },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c("label", [_vm._v("Ad Date")]),
                        _c("p", [
                          _vm._v(_vm._s(_vm.formatDate(_vm.contract.AdDate)))
                        ])
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("label", [_vm._v("TPR Begin Date")]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.formatDate(_vm.contract.TPRBeginDate))
                            )
                          ]),
                          _vm._l(_vm.contract.TPRDates, function(date) {
                            return _c("span", { key: date }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.formatDate(date.TPRBeginDate)) +
                                  " "
                              )
                            ])
                          })
                        ],
                        2
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("label", [_vm._v("TPR End Date")]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.formatDate(_vm.contract.TPREndDate))
                            )
                          ]),
                          _vm._l(_vm.contract.TPRDates, function(date) {
                            return _c("span", { key: date }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.formatDate(date.TPREndDate)) +
                                  " "
                              )
                            ])
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c("v-divider"),
          _c("v-data-table", {
            staticClass: "table",
            staticStyle: { cursor: "default" },
            attrs: {
              height: _vm.tableSize,
              headers: _vm.headers,
              items: _vm.contract.ContractItems,
              "items-per-page": _vm.pageSize,
              expanded: _vm.expanded,
              "item-key": "Item.OrderCode",
              "fixed-header": "",
              dense: "",
              "hide-default-footer": ""
            },
            on: {
              "update:items": function($event) {
                return _vm.$set(_vm.contract, "ContractItems", $event)
              },
              "update:expanded": function($event) {
                _vm.expanded = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "expanded-item",
                fn: function(ref) {
                  var headers = ref.headers
                  var item = ref.item
                  return [
                    _c(
                      "td",
                      { attrs: { colspan: headers.length } },
                      [
                        _c("small", [
                          _c("strong", [_vm._v("Case Cost:")]),
                          _vm._v(
                            " " + _vm._s(_vm.formatCurrency(item.Item.CaseCost))
                          )
                        ]),
                        _c("v-divider", {
                          staticClass: "ml-2 mr-2",
                          attrs: { vertical: "" }
                        }),
                        _c("small", [
                          _c("strong", [_vm._v("AMAP:")]),
                          _vm._v(" " + _vm._s(_vm.formatCurrency(item.AMAP)))
                        ]),
                        _c("v-divider", {
                          staticClass: "ml-2 mr-2",
                          attrs: { vertical: "" }
                        }),
                        _c("small", [
                          _c("strong", [_vm._v("EBA:")]),
                          _vm._v(" " + _vm._s(_vm.formatCurrency(item.EBA)))
                        ]),
                        _c("v-divider", {
                          staticClass: "ml-2 mr-2",
                          attrs: { vertical: "" }
                        }),
                        _c("small", [
                          _c("strong", [_vm._v("TPR BB:")]),
                          _vm._v(" " + _vm._s(_vm.formatCurrency(item.TPRBB)))
                        ]),
                        _c("v-divider", {
                          staticClass: "ml-2 mr-2",
                          attrs: { vertical: "" }
                        }),
                        _c("small", [
                          _c("strong", [_vm._v("Ad BB:")]),
                          _vm._v(" " + _vm._s(_vm.formatCurrency(item.AdBB)))
                        ]),
                        _c("v-divider", {
                          staticClass: "ml-2 mr-2",
                          attrs: { vertical: "" }
                        }),
                        _c("small", [
                          _c("strong", [_vm._v("Ad Scan:")]),
                          _vm._v(" " + _vm._s(_vm.formatCurrency(item.AdScan)))
                        ]),
                        _c("v-divider", {
                          staticClass: "ml-2 mr-2",
                          attrs: { vertical: "" }
                        }),
                        _c("small", [
                          _c("strong", [_vm._v("TPR Scan:")]),
                          _vm._v(" " + _vm._s(_vm.formatCurrency(item.TPRScan)))
                        ]),
                        _c("v-divider", {
                          staticClass: "ml-2 mr-2",
                          attrs: { vertical: "" }
                        }),
                        _c("small", [
                          _c("strong", [_vm._v("ePay Ad:")]),
                          _vm._v(" " + _vm._s(_vm.formatCurrency(item.ePayAd)))
                        ]),
                        _c("v-divider", {
                          staticClass: "ml-2 mr-2",
                          attrs: { vertical: "" }
                        }),
                        _c("small", [
                          _c("strong", [_vm._v("ePay TPR:")]),
                          _vm._v(" " + _vm._s(_vm.formatCurrency(item.ePayTPR)))
                        ]),
                        _c("v-divider", {
                          staticClass: "ml-2 mr-2",
                          attrs: { vertical: "" }
                        }),
                        _c("small", [
                          _c("strong", [_vm._v("Compete:")]),
                          _vm._v(" " + _vm._s(_vm.formatCurrency(item.Compete)))
                        ]),
                        _c("v-divider", {
                          staticClass: "ml-2 mr-2",
                          attrs: { vertical: "" }
                        }),
                        _c("small", [
                          _c("strong", [_vm._v("PCAF:")]),
                          _vm._v(" " + _vm._s(_vm.formatCurrency(item.PCAF)))
                        ]),
                        _c("v-divider", {
                          staticClass: "ml-2 mr-2",
                          attrs: { vertical: "" }
                        }),
                        _c("small", [
                          _c("strong", [_vm._v("Deal ID:")]),
                          _vm._v(" " + _vm._s(item.DealID))
                        ])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("v-divider"),
          _c("v-toolbar", { attrs: { flat: "", color: "#fafafa" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }