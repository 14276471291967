<template>
  <v-dialog v-model="show" persistent width="1200">
    <v-card color="#fafafa">
      <v-toolbar dark flat color="#1C3144">
        <v-btn icon dark @click.stop="show=false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{contract.Name}}</v-toolbar-title>
        <v-spacer />
          <v-toolbar-title>{{contract.ContractID}}</v-toolbar-title>
      </v-toolbar>
      <v-container class="ma-0 pa-6" id="wrapper">
        <div id="left">
          <v-row>
            <v-col cols="3">
              <label>Contract Type</label>
              <p>{{contract.ContractType}}</p>
            </v-col>
            <v-col cols="4">
              <label>Ad Group</label>
              <p>{{contract.AdGroup}}</p>
            </v-col>
            <v-col cols="4">
              <label>Supplier</label>
              <p>{{contract.Supplier}}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <label>Department</label>
              <p>{{contract.Department}}</p>
            </v-col>
            <v-col cols="9">
              <label>Manufacturer</label>
              <p>{{contract.Manufacturer}}</p>
            </v-col>
          </v-row>
        </div>
        <div id="right">
          <v-row>
            <v-col cols="3">
              <label>Ad Date</label>
              <p>{{formatDate(contract.AdDate)}}</p>
            </v-col>
            <v-col cols="3">
              <label>TPR Begin Date</label><br/>
              <span>{{formatDate(contract.TPRBeginDate)}}</span>
              <span v-for="date in contract.TPRDates" :key="date">
                {{formatDate(date.TPRBeginDate)}}
              </span>
            </v-col>
            <v-col cols="3">
              <label>TPR End Date</label><br/>
              <span>{{formatDate(contract.TPREndDate)}}</span>
              <span v-for="date in contract.TPRDates" :key="date">
                {{formatDate(date.TPREndDate)}}
              </span>
            </v-col>
          </v-row>
        </div>
      </v-container>
      <v-divider/>
      <v-data-table :height="tableSize" :headers="headers" :items.sync="contract.ContractItems"
        :items-per-page="pageSize" :expanded.sync="expanded" class="table" style="cursor:default;"
        item-key="Item.OrderCode" fixed-header dense hide-default-footer>

        <template v-slot:expanded-item="{ headers, item }">
        <!-- <td /> -->
        <td :colspan="headers.length">
          <small><strong>Case Cost:</strong> {{formatCurrency(item.Item.CaseCost)}}</small>
          <v-divider class="ml-2 mr-2" vertical />
          <small><strong>AMAP:</strong> {{formatCurrency(item.AMAP)}}</small>
          <v-divider class="ml-2 mr-2" vertical />
          <small><strong>EBA:</strong> {{formatCurrency(item.EBA)}}</small>
          <v-divider class="ml-2 mr-2" vertical />
          <small><strong>TPR BB:</strong> {{formatCurrency(item.TPRBB)}}</small>
          <v-divider class="ml-2 mr-2" vertical />
          <small><strong>Ad BB:</strong> {{formatCurrency(item.AdBB)}}</small>
          <v-divider class="ml-2 mr-2" vertical />
          <small><strong>Ad Scan:</strong> {{formatCurrency(item.AdScan)}}</small>
          <v-divider class="ml-2 mr-2" vertical />
          <small><strong>TPR Scan:</strong> {{formatCurrency(item.TPRScan)}}</small>
          <v-divider class="ml-2 mr-2" vertical />
          <small><strong>ePay Ad:</strong> {{formatCurrency(item.ePayAd)}}</small>
          <v-divider class="ml-2 mr-2" vertical />
          <small><strong>ePay TPR:</strong> {{formatCurrency(item.ePayTPR)}}</small>
          <v-divider class="ml-2 mr-2" vertical />
          <small><strong>Compete:</strong> {{formatCurrency(item.Compete)}}</small>
          <v-divider class="ml-2 mr-2" vertical />
          <small><strong>PCAF:</strong> {{formatCurrency(item.PCAF)}}</small>
          <v-divider class="ml-2 mr-2" vertical />
          <small><strong>Deal ID:</strong> {{item.DealID}}</small>
        </td>
      </template>
      </v-data-table>
      <v-divider/>
      <v-toolbar flat color="#fafafa">
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'

export default {
  name: 'ShuttleDetail',
  data () {
    return {
      pageSize: 2000,
      search: '',
      tableSize: '300',
      windowHeight: '',
      adGroup: '',
      invoiceType: '',
      adGroups: [],
      headers: [
        {
          text: '',
          align: 'left',
          sortable: false,
          filterable: false,
          value: ''
        },
        {
          text: 'Item Code',
          align: 'left',
          sortable: true,
          filterable: true,
          value: 'Item.OrderCode',
          class: 'black--text'
        },
        {
          text: 'Pack',
          align: 'left',
          sortable: true,
          filterable: true,
          value: 'Item.Pack',
          class: 'black--text'
        },
        {
          text: 'Size',
          align: 'left',
          sortable: true,
          filterable: true,
          value: 'Item.Size',
          class: 'black--text'
        },
        {
          text: 'UPC',
          align: 'left',
          sortable: true,
          filterable: true,
          value: 'Item.UPC',
          class: 'black--text'
        },
        {
          text: 'Item Description',
          align: 'left',
          sortable: true,
          filterable: true,
          value: 'Item.Description',
          class: 'black--text'
        }
      ],
      items: []
    }
  },
  props: [
    'value',
    'contract',
    'expanded'
  ],
  computed: {
    show: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    }
  },
  methods: {
    formatCurrency (value) {
      return '$' + parseFloat(value).toFixed(2)
    },
    formatDate (value) {
      if (!value) return 'N/A'
      return moment(value).format('M/D/YYYY')
    },
    close () {
      this.show = false
      this.adGroups = []
    }
  }
}
</script>

<style scoped>
.hide::-webkit-scrollbar {
  display: none;
}

.table >>> tbody tr.v-data-table__expanded__content {
  background: #eeeeee;
  box-shadow: none;
  cursor: default;
}

#wrapper {
  display: flex;
}

#left {
  flex: 0 0 50%;
}

#right {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

small {
  font-size: 85%;
}

label {
  font-weight: bold;
}

p {
  font-size: 14px;
}

</style>
